<footer class="bg-dark text-white">
  <div class="container py-3">
    <div class="row">
      <div class="col-sm-4">
        <p class="pt-3"><strong>Csper LLC 2019-2024</strong></p>

        <p class="text-muted">Making security easy by data driven insights</p>

        <p class="text-muted">Made with &hearts; in NYC.</p>
      </div>

      <div class="col-3 col-sm-2">
        <p class="pt-3"><strong>Company</strong></p>
        <a class="text-muted" [routerLink]="['/pricing']">Pricing</a> <br />
        <a class="text-muted" [routerLink]="['/about']">About Us</a> <br />
        <a class="text-muted" [routerLink]="['/privacy']">Privacy</a> <br />
        <a class="text-muted" [routerLink]="['/tos']">Terms</a> <br />
      </div>

      <div class="col-3 col-sm-2">
        <p class="pt-3"><strong>Products</strong></p>
        <a class="text-muted" [routerLink]="['/report-uri']">Report Collector</a><br />
        <a class="text-muted" [routerLink]="['/generator']">Policy Generator</a>
        <br />
        <a class="text-muted" [routerLink]="['/evaluator']">Policy Evaluator</a>
      </div>

      <div class="col-3 col-sm-2">
        <p class="pt-3"><strong>Resources</strong></p>
        <a class="text-muted" [routerLink]="['/docs']">Docs</a> <br />
        <a class="text-muted" [routerLink]="['/blog']">Blog</a> <br />
        <a class="text-muted" [routerLink]="['/faqs']">FAQs</a> <br />
        <a class="text-muted" [routerLink]="['/badges']">Badges</a> <br />
      </div>

      <div class="col-3 col-sm-2">
        <p class="pt-3"><strong>Contact</strong></p>
        <a routerLink="/contact" class="text-muted">Contact</a><br />
        <a class="text-muted" [routerLink]="['/docs', 'getting-started-csper']">Demo</a><br />
        <a href="https://twitter.com/csper_io" class="text-muted">&#64;csper_io</a><br />
        <!-- <span class="text-muted"><a href="https://github.com/csper-io"
                                                class="text-muted">Github</a></span> -->
      </div>
    </div>
  </div>
</footer>
