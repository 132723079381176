<div class="row">
  <div class="col-md-6">
    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Are there refunds?</div>
      <div class="card-body pt-1">
        <p class="card-text">Yes! If you are not happy with the service there is a 30 day refund.</p>
      </div>
    </div>

    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Does Csper store any credit card information?</div>
      <div class="card-body pt-1">
        <p class="card-text">
          No. All credit card activity and information is handled by our third-party provider, Stripe (see
          <a href="https://stripe.com/legal">Stripe’s Terms and Services</a>).
        </p>
      </div>
    </div>
    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What happens when the trial expires?</div>
      <div class="card-body pt-1">
        <p class="card-text">When the trial expires you will charged for the selected plan on a monthly basis.</p>
      </div>
    </div>
    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">Can I cancel?</div>
      <div class="card-body pt-1">
        <p class="card-text">Yes, at any point. Feel free to take advantage of the refund policy if you're not happy.</p>

        <p class="card-text">To cancel either cancel on the project settings page, or email support&#64;csper.io.</p>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What does "100,000 report processed / month" mean?</div>
      <div class="card-body pt-1">
        <p class="card-text">A "report" is a content-security-policy report that is sent to our endpoints.</p>

        <p class="card-text">
          After installing content-security-policy on your website, the violation reports are sent to csper.io for processing. Each report
          received counts towards the reports processed / month.
        </p>
      </div>
    </div>
    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">How is a month of reports counted?</div>
      <div class="card-body pt-1">
        <p class="card-text">Reports are counted from the start of the billing cycle.</p>
      </div>
    </div>
    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What happens if I run out of reports for the month?</div>
      <div class="card-body pt-1">
        <p class="card-text">New reports will not be analyzed.</p>
      </div>
    </div>
    <br />

    <div class="card border-0">
      <div class="card-header bg-white border-0 font-weight-bold mb-0 pb-0">What should I do if I have more questions?</div>
      <div class="card-body pt-1">
        <p class="card-text">Reach out to sales&#64;csper.io</p>
      </div>
    </div>
  </div>
</div>