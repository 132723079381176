<div class="container pt-65px">
  <h2>About Us</h2>

  <h3>Why?</h3>

  <div class="row">
    <div class="col-md-8">
      <p>
        Content Security Policy is known to be difficult. But so are lots of topics. We believe that any topic can be made easier when presented in
        the right way.
      </p>

      <p>So we built a tool to make Content Security Policy easier for all.</p>

      <p>Csper was built out of frustrations.</p>
      <ul>
        <li>
          Frustrated at the existing 'feel-good' security tools and documentation. The tools and documentation that will give you an A+ rating even
          though the policy is providing no security value.
        </li>
        <li>
          Frustrated that even after years of CSP being released, it's still difficult for non-security-experts get started with Content Security
          Policy.
        </li>
        <li>
          And frustrated that although many CSP tools were being built, but they weren't solving the real challenges that users face (the challenges I
          faced when deploying CSP on a real website).
        </li>
      </ul>
    </div>
    <div class="col-sm-4 d-none d-sm-block">
      <img src="https://assets.csper.io/csper.webp" class="rounded img-fluid" alt="founder" />
    </div>
  </div>

  <div>
    <h3>Where?</h3>
    <p>Currently based in NYC. But available everywhere</p>
  </div>

  <div>
    <h3>Who</h3>
    <div class="row">
      <div class="col-sm-4">
        <img src="https://assets.csper.io/me.webp" class="rounded img-fluid" alt="csper founder" />
      </div>
      <div class="col-sm-8">
        <h4>Stuart Larsen<small>&nbsp;Founder</small></h4>
        <!-- <p><a href="mailto:stuart@csper.io">stuart@csper.io</a> | <a href="https://c0nrad.io">c0nrad.io</a>
                        | <a href="https://github.com/c0nrad">github</a> | <a
                            href="https://linkedin.com/in/c0nrad">linkedin</a>
                    </p> -->
        <p>Stuart started programming when he was a kid to build (and sometimes cheat) at video games.</p>
        <p>He went on to do more useful programming and eventually became a security engineer at a variety of companies.</p>
        <p>After being tired of the difficulties and lack of tooling for CSP he founded Csper.</p>
        <p>
          In his free time he plays CTFs and uploads writeups to Youtube (<a href="https://www.youtube.com/@SloppyJoePirates/videos"
            >Sloppy Joe Pirates</a
          >).
        </p>
        <p>You can reach him at stuart&#64;csper.io</p>
      </div>
    </div>
  </div>

  <!-- <div class="container">
        <h2>About Us</h2>

        <div class="row">
            <div class="col-sm-8 col-xs-12">
                <blockquote class="blockquote text-right">
                    <p class="mb-0">Be the easiest way to build, evaluate, and monitor content-security-policy.
                    </p>
                </blockquote>

                <br>
                <p>We believe that the security of users is extremely important, but not everyone should have to
                    be
                    an
                    expert in everything. Content-Security-Policy is not an easy subject. It is constantly
                    changing
                    and
                    new
                    bypasses and recommendations are found
                    often.
                </p>

                <p>We built Csper with the goal of simplifing CSP rollout and maintenance for all teams. Not
                    just
                    those
                    with
                    dedicated web security experts.</p>

                <p>This way all teams (no matter size) can protect their users and spend more time focusing on
                    what
                    they
                    do
                    best.
                </p>
            </div>
            <div class="col-sm-4 d-none d-sm-block">
                <img src="https://assets.csper.io/csper.webp" class="rounded img-fluid" alt="founder">
            </div>
        </div> -->

  <div class="p-3"></div>
</div>

<app-footer></app-footer>
