import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderNavComponent } from "./components/header-nav/header-nav.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { NewProjectComponent } from "./pages/new-project/new-project.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { RegisterComponent } from "./pages/register/register.component";

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";

import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

import { LoadingBarModule } from "@ngx-loading-bar/core";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { AboutComponent } from "./pages/about/about.component";
import { FooterComponent } from "./components/footer/footer.component";
import { JoinComponent } from "./pages/join/join.component";
import { EvaluatorComponent } from "./pages/evaluator/evaluator.component";
import { ViewEvaluationComponent } from "./pages/view-evaluation/view-evaluation.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { EmailSummaryComponent } from "./pages/email-summary/email-summary.component";
import { BillingComponent } from "./pages/billing/billing.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogPostComponent } from "./pages/blog-post/blog-post.component";
import { PlaceholderComponent } from "./pages/placeholder/placeholder.component";
import { ScheduleDemoComponent } from "./pages/schedule-demo/schedule-demo.component";
import { CmsComponent } from "./pages/cms/cms.component";
import { DocsComponent } from "./pages/docs/docs.component";
import { ContentComponent } from "./components/content/content.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { GeneratorComponent } from "./pages/generator/generator.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { AdminProfilesComponent } from "./pages/admin-profiles/admin-profiles.component";
import { AdminProfileViewComponent } from "./pages/admin-profile-view/admin-profile-view.component";
import { ReportUriComponent } from "./pages/report-uri/report-uri.component";
import { NewOrganizationComponent } from "./pages/new-organization/new-organization.component";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { GlobalErrorHandler } from "./global-error-handler";
import { SecurityComponent } from "./pages/security/security.component";
import { AdminOrganizationsComponent } from "./pages/admin-organizations/admin-organizations.component";
import { EmailSubscribeComponent } from "./components/email-subscribe/email-subscribe.component";
import { TosComponent } from "./pages/tos/tos.component";
import { SharedModule } from "./shared/shared.module";
import { CommonModule } from "@angular/common";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { FeatherModule } from "angular-feather";
import { ChevronRight } from "angular-feather/icons";
import { WaveOneComponent } from "./components/wave-one/wave-one.component";
import { WaveTwoComponent } from "./components/wave-two/wave-two.component";
import { WaveThreeComponent } from "./components/wave-three/wave-three.component";
import { WaveBottomComponent } from "./components/wave-bottom/wave-bottom.component";
import { WaveTopComponent } from "./components/wave-top/wave-top.component";
import { WaveBottomDecoratedOneComponent } from "./components/wave-bottom-decorated-one/wave-bottom-decorated-one.component";
import { WaveTopDecoratedFlippedComponent } from "./components/wave-top-decorated-flipped/wave-top-decorated-flipped.component";
import { RapidsecComponent } from "./pages/rapidsec/rapidsec.component";
import { FaqsComponent } from "./components/faqs/faqs.component";
import { FaqsPageComponent } from "./pages/faqs-page/faqs-page.component";
import { BadgesComponent } from "./pages/badges/badges.component";
import { ProjectApikeyComponent } from './pages/project-apikey/project-apikey.component';
import { OrganizationApikeyComponent } from './pages/organization-apikey/organization-apikey.component';
import { AdminBillingOverageComponent } from './pages/admin-billing-overage/admin-billing-overage.component';
import { HasherComponent } from './pages/hasher/hasher.component';

// @ts-ignore
window.dataLayer = window.dataLayer || [];
function gtag() {
  // @ts-ignore
  dataLayer.push(arguments);
}

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        OrganizationsComponent,
        NewProjectComponent,
        HeaderNavComponent,
        PricingComponent,
        LoginComponent,
        RegisterComponent,
        PrivacyComponent,
        AboutComponent,
        FooterComponent,
        JoinComponent,
        EvaluatorComponent,
        ViewEvaluationComponent,
        NotFoundComponent,
        EmailSummaryComponent,
        BillingComponent,
        BlogComponent,
        BlogPostComponent,
        PlaceholderComponent,
        ScheduleDemoComponent,
        CmsComponent,
        DocsComponent,
        ContentComponent,
        MaintenanceComponent,
        GeneratorComponent,
        ContactComponent,
        AdminProfilesComponent,
        AdminProfileViewComponent,
        ReportUriComponent,
        NewOrganizationComponent,
        SecurityComponent,
        AdminOrganizationsComponent,
        EmailSubscribeComponent,
        TosComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        WaveOneComponent,
        WaveTwoComponent,
        WaveThreeComponent,
        WaveBottomComponent,
        WaveTopComponent,
        WaveBottomDecoratedOneComponent,
        WaveTopDecoratedFlippedComponent,
        RapidsecComponent,
        FaqsComponent,
        FaqsPageComponent,
        BadgesComponent,
        ProjectApikeyComponent,
        OrganizationApikeyComponent,
        AdminBillingOverageComponent,
        HasherComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        SharedModule,
        CommonModule,
        FeatherModule.pick({ ChevronRight })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
