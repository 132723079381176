import { ErrorHandler, Injectable } from "@angular/core";
import { TrackService } from "./services/track.service";
import { ErrorService } from "./services/error.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) {
    this.errorService.enableStackDriver();
  }
  handleError(error: any): void {
    console.log("GlobalErrorHandler.handleError", error, JSON.stringify(error));
    this.errorService.reportError(error);
  }
}
