<div class="container pt-65px">
  <h2>Schedule Demo</h2>

  <div *ngIf="!feedbackSent">
    <p>
      <i
        >An introductory call where we discuss your specific business needs, and a demo of Csper to see if it's a good fit. Feel free to email
        stuart&#64;csper.io with specific requirements.</i
      >
    </p>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" [(ngModel)]="feedback.first" class="form-control" id="firstName" aria-describedby="firstName" placeholder="First Name" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" [(ngModel)]="feedback.last" class="form-control" id="lastName" aria-describedby="lastName" placeholder="Last Name" />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        [(ngModel)]="feedback.email"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
    </div>

    <div class="form-group">
      <label for="exampleInputEmail1">Additional Comments</label>
      <textarea
        type="comments"
        [(ngModel)]="feedback.feedback"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Anything to help us tailor the demo"
      ></textarea>
    </div>
    <button class="btn btn-primary float-right" (click)="saveFeedback()">Submit</button>
  </div>

  <div class="alert alert-success" *ngIf="feedbackSent">
    <p><strong>Team Notified!</strong> Except an email shortly to schedule the demo.</p>
  </div>
</div>
