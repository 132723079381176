import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AnalysisComponent } from "./pages/analysis/analysis.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { NewProjectComponent } from "./pages/new-project/new-project.component";
import { OverviewComponent } from "./pages/overview/overview.component";
import { PolicyComponent } from "./pages/policies/policies.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { ProjectWrapperComponent } from "./pages/project-wrapper/project-wrapper.component";
import { OrganizationsComponent } from "./pages/organizations/organizations.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ReportAnalysisComponent } from "./pages/report-analysis/report-analysis.component";
import { ProjectSettingsComponent } from "./pages/project-settings/project-settings.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { AboutComponent } from "./pages/about/about.component";
import { JoinComponent } from "./pages/join/join.component";
import { EvaluatorComponent } from "./pages/evaluator/evaluator.component";
import { ViewEvaluationComponent } from "./pages/view-evaluation/view-evaluation.component";
import { BuilderComponent } from "./pages/builder/builder.component";
import { PolicyEvaluatorComponent } from "./pages/policy-evaluator/policy-evaluator.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { InlineComponent } from "./pages/inline/inline.component";
import { EmailSummaryComponent } from "./pages/email-summary/email-summary.component";
import { BillingComponent } from "./pages/billing/billing.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogPostComponent } from "./pages/blog-post/blog-post.component";
import { PlaceholderComponent } from "./pages/placeholder/placeholder.component";
import { EndpointComponent } from "./pages/endpoint/endpoint.component";
import { ScheduleDemoComponent } from "./pages/schedule-demo/schedule-demo.component";
import { environment } from "src/environments/environment";
import { CmsComponent } from "./pages/cms/cms.component";
import { DocsComponent } from "./pages/docs/docs.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { GeneratorComponent } from "./pages/generator/generator.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { AlertSettingsComponent } from "./pages/alert-settings/alert-settings.component";
import { AlertsComponent } from "./pages/alerts/alerts.component";
import { AlertEditComponent } from "./pages/alert-edit/alert-edit.component";
import { AdminProfilesComponent } from "./pages/admin-profiles/admin-profiles.component";
import { AdminProfileViewComponent } from "./pages/admin-profile-view/admin-profile-view.component";
import { ReportUriComponent } from "./pages/report-uri/report-uri.component";
import { OrganizationWrapperComponent } from "./pages/organization-wrapper/organization-wrapper.component";
import { ProjectUserManagementComponent } from "./pages/project-user-management/project-user-management.component";
import { OrganizationProjectsComponent } from "./pages/organization-projects/organization-projects.component";
import { OrganizationUserManagementComponent } from "./pages/organization-user-management/organization-user-management.component";
import { OrganizationSettingsComponent } from "./pages/organization-settings/organization-settings.component";
import { OrganizationUpgradeComponent } from "./pages/organization-upgrade/organization-upgrade.component";
import { AdminOrganizationsComponent } from "./pages/admin-organizations/admin-organizations.component";
import { TosComponent } from "./pages/tos/tos.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { NewOrganizationComponent } from "./pages/new-organization/new-organization.component";
import { RapidsecComponent } from "./pages/rapidsec/rapidsec.component";
import { FaqsPageComponent } from "./pages/faqs-page/faqs-page.component";
import { BadgesComponent } from "./pages/badges/badges.component";
import { AdminBillingOverageComponent } from "./pages/admin-billing-overage/admin-billing-overage.component";
import { HasherComponent } from "./pages/hasher/hasher.component";

const routes: Routes = [
  { path: "", component: HomeComponent },

  { path: "pricing", component: PricingComponent },
  { path: "evaluations/:evaluationID", component: ViewEvaluationComponent },

  // Products
  { path: "evaluator", component: EvaluatorComponent },
  { path: "generator", component: GeneratorComponent },
  { path: "report-uri", component: ReportUriComponent },
  { path: "hasher", component: HasherComponent },

  { path: "projects", component: OrganizationsComponent },
  { path: "organizations", component: OrganizationsComponent },

  { path: "new", component: NewOrganizationComponent },
  { path: "faqs", component: FaqsPageComponent },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "tos", component: TosComponent },
  { path: "about", component: AboutComponent },
  { path: "join/:projectID/:token", component: JoinComponent },
  { path: "demo", component: ScheduleDemoComponent },
  { path: "contact", component: ContactComponent },
  { path: "badges", component: BadgesComponent },

  // { path: "compare/rapidsec", component: RapidsecComponent },

  { path: "blog/:slug", component: BlogPostComponent },
  { path: "blog", component: BlogComponent },

  { path: "docs/:slug", component: DocsComponent },
  {
    path: "docs",
    redirectTo: "docs/content-security-policy",
    pathMatch: "full",
  },

  { path: "maintenance", component: MaintenanceComponent },
  { path: "placeholder", component: PlaceholderComponent },

  //ng generate module customers --route customers --module app.module
  {
    path: "p",
    // component: ProjectWrapperComponent,
    loadChildren: () => import("./project/project.module").then((m) => m.ProjectModule),
  },

  // {
  //   path: 'org/:organizationID',
  //   component: OrganizationWrapperComponent,
  //   children: [
  //     { path: 'projects', component: OrganizationProjectsComponent },
  //     { path: 'users', component: OrganizationUserManagementComponent },
  //     { path: 'billing', component: BillingComponent },
  //     { path: 'settings', component: OrganizationSettingsComponent },

  //     { path: 'upgrade', component: OrganizationUpgradeComponent },
  //   ]
  // },

  { path: "admin/profiles", component: AdminProfilesComponent },
  { path: "admin/profiles/:profileID", component: AdminProfileViewComponent },
  { path: "admin/organizations", component: AdminOrganizationsComponent },
  { path: "admin/billing", component: AdminBillingOverageComponent },
  // { path: 'admin/organizations/:organizationID', }

  {
    path: "org",
    loadChildren: () => import("./organization/organization.module").then((m) => m.OrganizationModule),
  },
];

if (!environment.production) {
  routes.push({ path: "cms", component: CmsComponent });
}

routes.push({ path: "404", component: NotFoundComponent }, { path: "**", redirectTo: "/404", pathMatch: "full" });

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
